import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor() { }
  
  // Create a BehaviorSubject to hold the boolean value
  private dataSource = new BehaviorSubject<boolean>(false);
  // Observable to expose the current boolean value
  currentBoolean = this.dataSource.asObservable();
  // Method to change the boolean value
  change(value: boolean) {
    console.log(value)
    this.dataSource.next(value);
  }
}
