import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public data:any;
  constructor(){}
  isAuthenticated() {
    this.data=sessionStorage.getItem('isLoggedIn');
    if(this.data=='1'){
      return true;
    }
    else{
      return false;
    }
  }
}
