import { Component, ElementRef, HostListener, OnChanges, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalVariableService } from './providers/gloal-variable';
import { SaveService } from './providers/save.service';
import { VoiceRecognitionService } from './providers/voice-recognition.service';
import { ChatService } from './providers/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'prowaaneeai';
  isLoginPage:boolean;
  constructor(public router: Router, private renderer: Renderer2, private chatServ: ChatService, private voiceServ: VoiceRecognitionService){}
  // isVoiceRecoginition:any
  isChat:any

  ngOnInit(){
    // Subscribe to the Voice Call service's boolean observable
    // this.voiceServ.currentBoolean.subscribe(value => {
    //   this.isVoiceRecoginition = value;
    // });
    // Subscribe to the Chat service's boolean observable
    this.chatServ.currentBoolean.subscribe(value => {
      this.isChat = value;
    });

    // // Handle click outside the dropdown to close it
    // this.renderer.listen('document', 'click', (event: Event) => {
    //   const dropdownContent2 = document.querySelector('.dropdown-content2');
    //   // const clickedInside = (event.target as HTMLElement).closest('.dropdown');
    //   console.log(dropdownContent2?.classList.contains('show'))
    //   if (!dropdownContent2?.classList.contains('show')) {
    //     dropdownContent2.classList.remove('show');
    //   }
    // });
    // this.getUserData()
    // this.router.events.subscribe(() => {
    //   this.isLoginPage = this.router.url === '/login';
    // });
  }

  // @HostListener('document:click', ['$event'])
  // handleClickOutside(event: Event) {
  //   const dropdownContent = document.querySelector('.dropdown-content3');
  //   dropdownContent.classList.toggle('show');
  // }
  // navClass:any='';
  // openPage(url:any) {
  //   this.navClass=url;
  //   this.router.navigateByUrl(url);
  //   const dropdownContent = document.querySelector('.dropdown-content2');
  //   dropdownContent.classList.remove('show');
  // }

  // userDetails:any
  // getUserData(){
  //   let userData = JSON.parse(localStorage.getItem('UserData'))
  //   this.userDetails = userData[0]
  //   console.log("userDetails-----------",this.userDetails)
  // }

  // logoutFun(){
  //   let formData = {
  //     id : this.userDetails.id,
  //     username: this.userDetails.username
  //   }
  //   this.saveServ.postData(this.globalVariable.logout, formData).subscribe((res:any)=>{
  //     console.log(res)
  //     if(res.status = true){
  //       localStorage.clear();
  //       sessionStorage.clear();
  //       this.router.navigate(['/login'])
  //     }
  //   })
  // }

  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent) {
  //   const createPopup = document.querySelector('.isVisiblePopup');
  //   const dropdownContent = document.querySelector('.dropdown-content2');
  //   console.log(createPopup, dropdownContent);
  //   if(dropdownContent.classList.contains('show')){
  //     dropdownContent.classList.remove('show');
  //   }
  //   if (!createPopup.classList.contains('addPopUp')) {
  //     createPopup.classList.add('addPopUp');
  //   }
  // }



}
