import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    // Split the text into sentences based on periods, question marks, or exclamation marks
    return value.replace(/(?:^|\.\s|\?\s|\!\s)(\w)/g, (match) => match.toUpperCase());
  }

}
