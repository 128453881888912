<div class="chat-win">
    <div class="chat-panel chatClass">
        <div class="chat-header">
            <h5>{{userData.username | uppercase}}</h5>
            <div class="action-btn">
                <!-- <button class="btn" (click)="slideWinFun()" *ngIf="windowStatus"><img src="assets/img/maxwin.png" ></button> -->
                <!-- <button class="btn" (click)="minFun()" *ngIf="!windowStatus"><img src="assets/img/minwin.png"></button> -->
                <button class="btn" (click)="close()"><img src="assets/img/closeIcon.png"></button>
            </div>
        </div>
        <div class="chat-body" >
            <div *ngFor="let msg of chatDetails">
                <p class="send" *ngIf="msg.status == 'sender'"><span>{{msg.msg}}</span></p>
                <p class="receive" *ngIf="msg.status == 'receiver'">
                    <span>{{msg.msg}}</span><br>
                    <a (click)="slideWinFun(msg.id)" class="replay-btn"><span (mouseover)="showReplayFun()">replay</span></a>
                    <span *ngIf="msg.replayMsg" class="replay-msg">
                        {{msg.replayMsg}}
                    </span>
                </p>
            </div>
        </div>
        <div class="chat-feature">
            <!-- <form > -->
            <div class="feature-field">
                <input type="text" class="form-control" placeholder="send message" #sendMsg>
            </div>
            <button class="btn send-btn" (click)="sendFun(sendMsg.value)">send</button>
            <!-- </form> -->
        </div>
    </div>
    
    <div class="chat-aiTrain chatClass">
        <div class="chat-header">
            <h5>AI training</h5>
            <div class="action-btn">
                <button class="btn" (click)="minFun()" *ngIf="!windowStatus"><img src="assets/img/closeIcon.png"></button>
            </div>
        </div>
        <div class="chat-body">
            <p class="receive"><span>Hello</span></p>
        </div>
        <div class="chat-feature">
            <div class="feature-field">
                <input type="text" class="form-control" placeholder="send message" #replayVar>
            </div>
            <button class="btn send-btn" (click)="trainAi(replayVar.value)">Save</button>
        </div>
    </div>
</div>