import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helper/auth.guard';

const routes: Routes = [
  {path:'', loadChildren:()=> import('./includes/home/home.module').then(m => m.HomeModule),  canActivate: [AuthGuard]},
  {path:'auth', loadChildren:()=> import('./auth/auth.module').then(m => m.AuthModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
