import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VoiceRecognitionService } from '../providers/voice-recognition.service';

@Component({
  selector: 'app-create-voice',
  templateUrl: './create-voice.component.html',
  styleUrls: ['./create-voice.component.scss']
})
export class CreateVoiceComponent implements OnInit {
  public isVoice:any
  public voiceText:any
  public isUserSpeaking: boolean = false;
  constructor(
    private voiceRecognitionServ: VoiceRecognitionService
  ){}

  ngOnInit(): void {
   
  }
  ngAfterViewInit(){
    this.voiceRecognitionServ.currentBoolean.subscribe(value => {
      this.isVoice = value;
      if(this.isVoice == true){
        this.initVoiceInput();
        this.startRecording();
      }
    });
  }

  /**
   * @description Function for initializing voice input so user can chat with machine.
   */
  initVoiceInput() {
    // Subscription for initializing and this will call when user stopped speaking.
    this.voiceRecognitionServ.init().subscribe(() => {
      // User has stopped recording
      // Do whatever when mic finished listening
      this.voiceText = ""
    });

    // Subscription to detect user input from voice to text.
    this.voiceRecognitionServ.speechInput().subscribe((input) => {
      // Set voice text output to
      this.voiceText = input
    });
  }

  /**
   * @description Function to enable voice input.
   */
  startRecording() {
    // Toggle the boolean value
    this.isUserSpeaking = true;
    this.voiceRecognitionServ.start();
  }

    /**
   * @description Function to stop recording.
   */
    stopRecording() {
      let voiceCall:any = document.querySelector('.create-voice')
      voiceCall.classList.toggle('active')
      this.voiceRecognitionServ.change(false);
      
      this.voiceRecognitionServ.stop();
      this.isUserSpeaking = false;
    }

}
