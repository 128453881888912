import { Component, OnInit } from '@angular/core';
import { ChatService } from '../providers/chat.service';

@Component({
  selector: 'app-create-chat',
  templateUrl: './create-chat.component.html',
  styleUrls: ['./create-chat.component.scss']
})
export class CreateChatComponent implements OnInit {
  userData:any
  windowStatus:any=true
  replayId:any
  chatDetails:any=[
    {
      id:1,
      name:'John Doe',
      msg:'Hii',
      status:'sender'
    },
    {
      id:2,
      name:'Sam Hanry',
      msg:'Hello',
      status:'receiver'
    }
  ]

  constructor(private chatServ:ChatService) { }
  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('UserData'));
    console.log(this.userData)
  }


  slideWinFun(id:any){
    this.replayId = id
    this.windowStatus=false
    let chatpanel = document.querySelector('.chat-aiTrain')
    chatpanel.classList.toggle('max')
  }
  minFun(){
    this.windowStatus=true
    let chatpanel = document.querySelector('.chat-aiTrain')
    chatpanel.classList.toggle('max')
  }
  close(){
    this.chatServ.change(false);
  }
  sendFun(sendMsg:any){
    let obj:any = {}
    obj.id = this.chatDetails.length +1
    obj.msg = sendMsg
    obj.status = 'sender'
    this.chatDetails.push(obj)
    console.log(this.chatDetails, sendMsg)
  }
  showReplayFun(){
    let replayMsg:any = document.querySelector('.replay-msg')
    replayMsg.style.display = 'inline-block'
  }
  trainAi(chatVal:any){
    console.log(chatVal)
    let chatpanel = document.querySelector('.chat-aiTrain')
    chatpanel.classList.toggle('max')

    if(this.replayId){
      let obj = this.chatDetails.find((item:any) => item.id == this.replayId)
      obj.replayMsg = chatVal
    }
  }
}
