import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariableService {
// Server site links
    // serverLink:any = 'http://localhost:3000';
    serverLink:any = 'https://owlexadata.waanee.ai'
// Websocket Link
    webSocketUrl='wss://owlexa.waanee.ai:2929/waanee'
//  Auth API's
    login = this.serverLink+ '/user/login';
    signup = this.serverLink+ '/user/add';
    logout = this.serverLink+ '/user/logout';
    forgotPassword = this.serverLink+ '/user/forgot'

// Assistants API's
    addAssistant = this.serverLink + '/assistant/add';
    getAssistant = this.serverLink + '/assistant/get';
    getAssistantById = this.serverLink + '/assistant/getbyid';
    updateAssistant = this.serverLink + '/assistant/update';
    deleteAssistant = this.serverLink + '/assistant/delete';

// knowledgebase API's
    addknowledgebase = this.serverLink + '/knowledgebase/add';
    getknowledgebase = this.serverLink + '/knowledgebase/get';
    getknowledgebasebyId = this.serverLink + '/knowledgebase/getbyId';
    updateknowledgebase = this.serverLink + '/knowledgebase/update';
    deleteknowledgebase = this.serverLink + '/knowledgebase/delete';

// workflow API's
    addworkflow = this.serverLink + '/workflow/add';
    getworkflow = this.serverLink + '/workflow/get';
    getworkflowbyId = this.serverLink + '/workflow/getbyId';
    updateworkflow = this.serverLink + '/workflow/update';
    deleteworkflow = this.serverLink + '/workflow/delete';


// Call Logs API's
    addcallLogs = this.serverLink + '/callLogs/add';
    getcallLogs = this.serverLink + '/callLogs/get';
    getcallLogsbyName = this.serverLink + '/callLogs/getbyName';


}
