import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { map } from 'rxjs/operators';
import { GlobalVariableService } from './gloal-variable';

declare var webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root'
})
export class VoiceRecognitionService {
  // websocket connection
  private socket$: WebSocketSubject<any>;

  // Create a BehaviorSubject to hold the boolean value
  private dataSource = new BehaviorSubject<boolean>(false);
  // Observable to expose the current boolean value
  currentBoolean = this.dataSource.asObservable();

  recognition: any;
  isStoppedSpeechRecog = false;
  public text = '';
  private voiceToTextSubject: Subject<string> = new Subject();
  private speakingPaused: Subject<any> = new Subject();
  private tempWords: string = '';
  constructor(private globalVariable:GlobalVariableService) { 
    this.socket$ = webSocket(globalVariable.webSocketUrl);
  }

  // Method to change the boolean value
  change(value: boolean) {
    console.log(value)
    this.dataSource.next(value);
  }


  /**
   * @description Function to return observable so voice sample text can be send to input.
   */
  speechInput() {
    return this.voiceToTextSubject.asObservable();
  }

  /**
   * @description Function to initialize voice recognition.
   */
  init() {
    this.recognition = new webkitSpeechRecognition();
    this.recognition.interimResults = true;
    this.recognition.lang = 'en-US';

    this.recognition.addEventListener('result', (e: any) => {
      const transcript = Array.from(e.results)
        .map((result: any) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript;
      this.voiceToTextSubject.next(this.text || transcript);
    });
    return this.initListeners();
  }

  /**
   * @description Add event listeners to get the updated input and when stoped
   */
  initListeners() {
    this.recognition.addEventListener('end', (condition: any) => {
      this.recognition.stop();
    });
    return this.speakingPaused.asObservable();
  }

  /**
   * @description Function to mic on to listen.
   */
  start() {
    this.text = '';
    this.isStoppedSpeechRecog = false;
    this.recognition.start();
    this.recognition.addEventListener('end', (condition: any) => {
      if (this.isStoppedSpeechRecog) {
        this.recognition.isActive = true;
        this.recognition.stop();
      } else {
        this.isStoppedSpeechRecog = false;
        this.wordConcat();
        // Checked time with last api call made time so we can't have multiple start action within 200ms for countinious listening
        // Fixed : ERROR DOMException: Failed to execute 'start' on 'SpeechRecognition': recognition has already started.
        if (!this.recognition.lastActiveTime || (Date.now() - this.recognition.lastActive) > 200) {
          this.recognition.start();
          this.recognition.lastActive = Date.now();
        }
      }
      this.voiceToTextSubject.next(this.text);
    });
  }

  /**
   * @description Function to stop recognition.
   */
  stop() {
    this.text = '';
    this.isStoppedSpeechRecog = true;
    this.wordConcat()
    this.recognition.stop();
    this.recognition.isActive = false;
    this.speakingPaused.next('Stopped speaking');
  }

  /**
   * @description Merge previous input with latest input.
   */
  wordConcat() {
    console.log(this.text,"----------", this.tempWords)
    // this.text = this.text.trim() + ' ' + this.tempWords;
    // this.text = this.text.trim();
    this.text = this.tempWords;
    this.tempWords = '';
  }

  // WebSocket Event Functions Start
   // Send a message to the WebSocket server
   sendMessage(message: any): void {
    this.socket$.next(message);
  }
  // Close the WebSocket connection
  close(): void {
    this.socket$.complete();
  }
  // Listen for messages from the WebSocket server
  onMessage(): Observable<any> {
    return this.socket$.pipe(
      map((message) => {
        // Process the incoming message
        return message;
      })
    );
  }
  // Handle errors
  onError(): Observable<any> {
    return this.socket$.multiplex(
      () => ({ subscribe: 'errors' }),
      () => ({ unsubscribe: 'errors' }),
      message => message.type === 'error'
    );
  }
  // Function End
}
